import { getBg, page3Panels, page3Panels2, urlBase, getBgAttachment, getBgSize } from "./constants";
import { Page3Panels } from "./page3panels";
import { NavBarLeft } from "./navbarleft";
import { useState } from "react";

export const Work = ({ theme, globalSw, headerLinkClick, showSubtext, setCurrentPageI, pageI }) => {

    // const [pageI, setPageI] = useState(0);

    const movePage = (offset) => {
        if ((pageI + offset) < 0) {
            headerLinkClick(1);
            // setCurrentPageI(0);
        } else if ((pageI + offset) > 1) {
            headerLinkClick(3);
            // setCurrentPageI(0);
        } else {
            setCurrentPageI(pageI + offset)
        }
    }


    return (
        <>
            <div
                id="page3"
                style={{
                    backgroundImage: getBg(3, theme, globalSw),
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: getBgSize(3, theme, globalSw), backgroundAttachment: getBgAttachment(3, theme, globalSw)
                }}
                className="hidden lg:flex  flex-col z-20    lg:h-screen w-screen  py-20 lg:py-0 "
            >
                <div className="flex w-full h-full mb-20">


                    <div className={" flex flex-col w-full "}>
                        <div className={" center-text-6xl-2 pl-14 " + (theme == 'cherry' ? ' ml-custom-1 ' : 'm-auto')}>
                            {pageI === 0 ? (
                                <div className="slidein text-custom2 lg:text-5xl ">
                                    What We Do Best
                                </div>
                            ) : (
                                <></>
                            )}
                            {pageI === 1 ? (
                                <div className="slidein text-custom2 lg:text-5xl xl:text-5xl m-auto">
                                    Our Strategy for Political Impact
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        {/* <div className=" hidden h-32 lg:flex items-center ml-auto mt-auto h-20 mb-12 mr-8 text-2xl cursor-pointer hover:text-amber-400">
                        <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0  hover:font-bold">&#10229;</div> <div className="font-18">Strategy{" "}</div>
                        <div onClick={() => movePage(1)} className="text-custom2  relative bottom-0 hover:font-bold">&#10230;</div>
                    </div> */}
                        {/* 
                    <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                        <div className=""></div>
                        <div className=" hidden lg:flex items-center text-2xl  ml-52 cursor-pointer hover:text-amber-400">
                            <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0  hover:font-bold">&#10229;</div> <div className="font-18">{pageI == 0 ? 'Work' : 'Strategy'}{" "}</div>
                            <div onClick={() => movePage(1)} className="text-custom2  relative bottom-0 hover:font-bold">&#10230;</div>
                        </div>
                        <div className=""></div>

                    </div> */}

                    </div>
                    <Page3Panels theme={theme} pageI={pageI} />

                </div>


                <div className="w-screen  hidden lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                    <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer ">
                        <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0 hover:text-amber-400  hover:font-bold">&#10229;</div>
                        <div onClick={() => movePage(1)} className="text-custom2  relative bottom-0 hover:text-amber-400 hover:font-bold">&#10230;</div>
                    </div>

                </div>

            </div >

            <div
                id="page3Mobile1"
                style={{
                    backgroundImage: getBg(3, theme, globalSw),
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
                className="flex lg:hidden  lg:h-screen   flex-col  py-20 lg:py-0 "
            >
                <div className=" flex flex-col  px-8">
                    <div className="slidein text-3xl my-4 lg:m-auto">

                        What We Do Best

                    </div>
                    <div className="flex flex-col lg:grid grid-cols-2 grid-flow-row  ">
                        {page3Panels.map((x, i) => (
                            <div key={i} className="flex  h-auto  my-4   flex-col">
                                <div className="text-custom1 font-semibold mb-2">{x.title}</div>
                                <div className="text-custom1">{x.content}</div>
                            </div>
                        ))}
                    </div>
                    <div className="slidein text-3xl  mb-4 mt-20 ">
                        Our Strategy for Political Impact
                    </div>
                    <div className="flex flex-col lg:grid grid-cols-2 grid-flow-row">
                        {page3Panels2.map((x, i) => (
                            <div key={i} className="flex  h-auto my-4  flex-col">
                                <div className="text-custom1 font-semibold mb-2">{x.title}</div>
                                <div className="text-custom1">{x.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* <div
                id="page3Mobile2"
                style={{
                    backgroundImage: getBg(3, theme, globalSw),
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
                className="flex lg:hidden  lg:h-screen   flex-col"
            >
                <div className=" flex flex-col mt-8 px-8">
                    <div className="slidein text-3xl  my-4 ">
                        What We Do Best
                    </div>
                    <div className="flex flex-col lg:grid grid-cols-2 grid-flow-row">
                        {page3Panels2.map((x, i) => (
                            <div key={i} className="flex  h-auto my-4  flex-col">
                                <div className="text-custom1 font-semibold mb-2">{x.title}</div>
                                <div className="text-custom1">{x.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}

        </>
    )
}