import { getBg, getBgAttachment, getBgSize } from "./constants"
import { NavBarLeft } from "./navbarleft"

export const Approach = ({ theme, showSubtext, globalSw, headerLinkClick }) => {
    return (
        <div
            id="page4"
            style={{ backgroundImage: getBg(2, theme, globalSw), backgroundRepeat: 'no-repeat', backgroundSize: getBgSize(2, theme, globalSw), backgroundAttachment: getBgAttachment(2, theme, globalSw), backgroundPositionY: '-4px' }}
            className=" flex-col z-10  flex  h-auto lg:h-screen w-screen  py-20 lg:py-0"
        >
            <div className="mb-8 flex flex-col max-w-screen-2xl lg:my-auto px-8 h-full">
                <div className={" h-full flex flex-col max-w-2xl mx-auto  mt-8 lg:mt-0 " + ((theme == 'cherry' && globalSw > 1024) ? ' max-w-lg mr-custom-2' : '')} >
                    <div className="center-text-6xl text-3xl lg:text-5xl xl:text-5xl mb-4">
                        We Engage the Ecosystem
                    </div>
                    {showSubtext ?
                        <div className="flex flex-col slidein  text-custom1 lg:font-18 ">

                            <div className=" mb-8">
                                It’s not enough to just walk the halls of Congress. Success today requires engaging a whole ecosystem of decision-makers, influencers, and allies.

                            </div>


                            <div className=" grid  grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-8">
                                <div className="flex flex-col">
                                    <div className=" font-semibold  mb-1">Congress</div>
                                    <div className="flex flex-col">
                                        <div className="mb-1">Committees</div>
                                        <div className="mb-1">Champions</div>
                                        <div className="mb-1">Leadership</div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold  mb-1">Administration</div>
                                    <div className="flex flex-col">
                                        <div className="mb-1">West Wing</div>
                                        <div className="mb-1">Agencies</div>

                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold mb-1">
                                        Society
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="mb-1">Coalitions</div>
                                        <div className="mb-1">NGOs</div>
                                        <div className="mb-1">Media</div>
                                        <div className="mb-1">Thought leaders</div>

                                    </div>
                                </div>
                            </div>


                        </div> :
                        <div className=" opacity-0 flex flex-col   text-custom1 lg:font-18 ">

                            <div className=" mb-8">
                                It’s not enough to just walk the halls of Congress. Success today requires engaging a whole ecosystem of decision-makers, influencers, and allies.

                            </div>


                            <div className=" grid  grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-8">
                                <div className="flex flex-col">
                                    <div className=" font-semibold">Congress</div>
                                    <div className="flex flex-col">
                                        <div className="mb-2">Committees</div>
                                        <div className="mb-2">Champions</div>
                                        <div className="mb-2">Leadership</div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Administration</div>
                                    <div className="flex flex-col">
                                        <div className="mb-2">West Wing</div>
                                        <div className="mb-2">Agencies</div>

                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">
                                        Society
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="mb-2">Coalitions</div>
                                        <div className="mb-2">NGOs</div>
                                        <div className="mb-2">Media</div>
                                        <div className="mb-2">Thought leaders</div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    }
                </div>
                {/* <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                    <div className=""></div>
                    <div className=" hidden lg:flex items-center text-2xl   mx-auto cursor-pointer hover:text-amber-400">
                        <div onClick={() => headerLinkClick(2)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="hover:text-amber-400 font-18">What We Do{" "}</div>
                        <div onClick={() => headerLinkClick(4)} className="text-custom2 relative bottom-0 hover:font-bold">&#10230;</div>
                    </div>
                    <div className=""></div>

                </div> */}
            </div>


            <div className="w-screen hidden h-20 lg:flex lg:mb-10 items-end fixed bottom-0 z-10" style={{ transform: 'translate(0)' }} >

                <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer ">
                    <div onClick={() => headerLinkClick(2)} className="text-custom2 relative bottom-0  hover:text-amber-400 hover:font-bold">&#10229;</div>
                    <div onClick={() => headerLinkClick(4)} className="text-custom2 relative bottom-0 hover:text-amber-400 hover:font-bold">&#10230;</div>
                </div>

            </div>
        </div>
    )
}