import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getBg, getBgAttachment, getBgSize, teamText, urlBase } from "./constants";
import Dialog from '@mui/material/Dialog';




const TeamScrollOver = ({ show, onClose, theme, teamI }) => {
    const scrollRef = useRef(null);
    const [showMore, setShowMore] = useState(true);
    const scrollDown = (e) => {
        e.stopPropagation();
        const el = scrollRef.current;
        const diff = scrollRef.current.scrollHeight - window.innerHeight;
        el.scroll(0, diff);

    }
    useEffect(() => {
        checkShowMore()

        scrollRef.current.onscroll = (() => {
            checkShowMore()
        });

    }, []);
    const checkShowMore = () => {
        const diff = scrollRef.current.scrollHeight - window.innerHeight;
        if (scrollRef.current.scrollTop >= (diff - 1)) {
            setShowMore(false);
        } else {
            setShowMore(true);
        }
    }

    useEffect(() => {
        checkShowMore();
        scrollRef.current.scroll(0, 0)
    }, [show])
    return (
        <div className={' left-0 top-0 w-screen h-screen  bg-black bg-opacity-50 z-20 slideoverteamcontainer ' + (show ? 'absolute' : 'hidden')} onClick={() => onClose()}>
            <div onClick={(e) => e.stopPropagation()} ref={scrollRef} className={' absolute flex flex-col slideoverteam shadow-xl shadow-black overflow-y-scroll right-0 slideinleft h-screen ' + (theme === 'dark' ? ' bg-neutral-700' : 'bg-white')}>
                <div className='w-full flex mt-2'>
                    <div className='w-fit ml-auto mr-4'>
                        <IconButton onClick={() => onClose()}
                        >
                            <CloseIcon />
                        </IconButton>

                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-2 mb-4 px-0 pt-4 ml-8'>
                    <div className='flex'>
                        <img src={teamText[teamI]?.modalImg} className=" w-full my-auto  " />

                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-col my-auto'>
                            <div className='font-semibold text-3xl'>{teamText[teamI]?.name}</div>
                            <div className=' text-xl '>{teamText[teamI]?.role}</div>

                            <div className="flex w-fit relative right-2  text-black   ">
                                <IconButton href={teamText[teamI]?.linkedInLink} target='_blank'
                                >
                                    <LinkedInIcon fontSize="small" />
                                </IconButton>
                                <IconButton href={teamText[teamI]?.emailLink} target='_blank'
                                >
                                    <EmailIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='text-base lg:text-xs  px-8 pb-12 scale-modal' dangerouslySetInnerHTML={{ __html: teamText[teamI]?.bio }}></div>
                {showMore ? <div className={' fixed bottom-0 slideoverteam w-full h-auto flex ' + (theme === 'dark' ? ' bg-neutral-700' : 'bg-white')} onClick={(e) => e.preventDefault()} >
                    <div className='w-fit mx-auto py-2 '>
                        <Button variant='text' defaultbutton={1} onClick={(e) => scrollDown(e)}>More</Button>

                    </div>

                </div> : <></>}

            </div>
        </div>
    )
}


export const TeamDialog = ({ show, onClose, theme, teamI }) => {

    return (
        <Dialog onClose={onClose} open={show}>
            <div className='flex flex-col px-4 mb-6'>
                <div className='flex flex-col items-center mb-4'>
                    <div className='w-full flex mb-2 mt-1'>
                        <div className='w-fit mr-4'>
                            <IconButton onClick={() => onClose()}
                            >
                                <CloseIcon />
                            </IconButton>

                        </div>
                    </div>
                    <div className='flex mx-auto'>
                        <img src={teamText[teamI]?.modalImg} className=" w-52 mx-auto " />

                    </div>
                    <div className='flex flex-col mx-auto'>
                        <div className='flex flex-col mx-auto'>
                            <div className='font-bold text-3xl text-center'>{teamText[teamI]?.name}</div>
                            <div className=' text-xl text-center'>{teamText[teamI]?.role}</div>

                            <div className="flex w-fit mx-auto  text-black   ">
                                <IconButton href={teamText[teamI]?.linkedInLink} target='_blank'
                                >
                                    <LinkedInIcon fontSize="small" />
                                </IconButton>
                                <IconButton href={teamText[teamI]?.emailLink} target='_blank'
                                >
                                    <EmailIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='text-base scale-modal' dangerouslySetInnerHTML={{ __html: teamText[teamI]?.bio }}></div>
            </div>

        </Dialog>
    )
}




export const Team = ({ theme, globalSw, showSubtext, headerLinkClick }) => {

    const [pageI, setPageI] = useState(0);

    const [open, setOpen] = useState(false);
    const [teamI, setTeamI] = useState(null);

    const openTeamDialog = (i) => {
        setOpen(true);
        setTeamI(i);
    }

    const handleClose = () => {
        setOpen(false);
        setTeamI(null);
    }

    const movePage = (offset) => {
        if ((pageI + offset) < 0) {
            headerLinkClick(5);
            // setPageI(0);
        } else if ((pageI + offset) > 3) {
            headerLinkClick(7);
            // setPageI(0);
        } else {
            setPageI(pageI + offset);
        }
    }
    return (
        <div
            id="page7"
            style={{ backgroundImage: getBg(6, theme, globalSw), backgroundSize: getBgSize(6, theme, globalSw), backgroundAttachment: getBgAttachment(6, theme, globalSw) }}
            className=" flex-col z-30   flex lg:h-screen w-screen py-20 lg:py-0  "
        >
            <div className="flex flex-col  px-8 lg:mt-8  lg:ml-20 h-full">
                <div className="flex flex-col lg:grid grid-cols-2 lg:gap-x-8  h-full lg:mb-20">
                    <div className="flex w-auto lg:relative  ">
                        {(pageI < 2) ? <div className={" center-text-6xl max-w-lg text-3xl lg:text-5xl xl:text-6xl" + (globalSw < 1024 ? '' : (theme == 'cherry' ? ' ml-custom-1    ' : ' ml-auto mr-4'))}>
                            Universal Name Recognition
                        </div> :

                            <div className={" center-text-6xl slidein max-w-xl text-3xl lg:text-5xl xl:text-6xl" + (globalSw < 1024 ? '' : (theme == 'cherry' ? ' ml-custom-1-2  ' : ' ml-auto mr-4'))}>
                                An Experienced Bipartisan Staff
                            </div>
                        }

                    </div>
                    <div className="lg:my-auto flex flex-col lg:flex-row h-full">
                        {((pageI == 0 || globalSw < 1024) && showSubtext) ? (
                            <div className="slidein text-custom1 my-auto lg:relative lg:top-0 lg:font-18 flex flex-col  ">
                                <div className="mb-4">
                                    Guided by the seasoned leadership of former Senate Majority Leader Tom Daschle, our firm taps into a wealth of insider knowledge and a deep understanding of the intricacies of Capitol Hill. We don't just know politics; we've shaped it.

                                </div>

                            </div>
                        ) : (
                            <></>
                        )}
                        {(pageI == 1) || globalSw < 1024 ? (
                            <div className="slidein flex flex-col text-custom1 font-18 my-auto lg:relative">
                                <img src={`${teamText[10].img}`} className=" cursor-pointer lg:grayscale " onClick={() => openTeamDialog(10)} />
                                <div className="font-semibold text-center lg:text-left mt-2 lg:mt-0 mb-1 lg:mb-4">Senator Thomas A. Daschle</div>
                                <div className="font-semibold text-center lg:text-left mb-4">Founder & CEO</div>
                                <div className="text-center lg:text-left">
                                    Former Senate majority leader who has participated in
                                    the development of almost every major public policy
                                    issue of the last three decades
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {(((pageI == 2) || globalSw < 1024) && showSubtext) ? (
                            <div className="slidein text-custom1 my-auto lg:relative  lg:font-18 flex flex-col  ">
                                <div className="lg:hidden font-semibold mt-16 mb-3">An Experienced Bipartisan Staff</div>
                                <div className="lg:mb-4">
                                    We are Capitol Hill veterans, lawyers, and political operatives. We are bipartisan and bicameral. We have worked all aspects of public affairs campaigns and leveraged our diverse backgrounds to create strategies that win.
                                </div>

                            </div>
                        ) : (
                            <></>
                        )}
                        {(pageI == 3) || globalSw < 1024 ? (
                            // <div className='flex flex-col imgcontainertest my-auto'>
                            //     <div className='flex'>
                            //         {teamText.slice(0, 2).map((x, i) => <>
                            //             <img className=' imgtest' src={x.img} />
                            //         </>)}
                            //     </div>
                            //     <div className='flex'>
                            //         {teamText.slice(2, 4).map((x, i) => <>
                            //             <img className=' imgtest' src={x.img} />
                            //         </>)}
                            //     </div>
                            //     <div className='flex '>
                            //         {teamText.slice(4, 6).map((x, i) => <>
                            //             <img className='imgtest' src={x.img} />
                            //         </>)}
                            //     </div>

                            // </div>
                            <div className="slidein flex flex-col my-auto lg:grid grid-cols-4 gap-y-0 grid-flow-row gap-x-1 mt-16 lg:mt-0 team-overflow">
                                {teamText.slice(0, teamText.length - 1).map((x, i) => (
                                    <div className='flex'>

                                        <div key={i} className="text-base flex flex-col mb-4 lg:mb-1  mx-auto" >
                                            <img src={x.img} className=" w-auto mx-auto  lg:grayscale imgtest lg:self-start lg:mx-0 lg:mb-2 cursor-pointer" onClick={() => openTeamDialog(i)} />
                                            <div className="font-semibold text-base text-center lg:text-left  lg:text-xs">{x.name}</div>
                                            <div className="font-semibold text-base text-center lg:text-left lg:text-xs">{x.role}</div>
                                            <div className="text-base text-center lg:text-left lg:text-xs">{x.text}</div>
                                            <div className="flex w-fit lg:w-full mx-auto relative bottom-1  lg:scale-75 z-20    ">
                                                <IconButton href={x.linkedInLink} target='_blank'
                                                >
                                                    <LinkedInIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton href={x.emailLink} target='_blank'
                                                >
                                                    <EmailIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {/* 
                <div className="w-full h-20 mb-12 flex">
                    <div className=" hidden lg:flex items-center text-2xl ml-auto cursor-pointer hover:text-amber-400">
                        <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="font-18 hover:text-amber-400">Team{" "}</div>
                        <div onClick={() => movePage(1)} className="text-custom2 relative bottom-0  hover:font-bold">&#10230;</div>
                    </div>
                </div> */}

                {/* <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                    <div className=""></div>
                    <div className=" hidden lg:flex items-center text-2xl mx-auto cursor-pointer hover:text-amber-400">
                        <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="font-18 hover:text-amber-400">Team{" "}</div>
                        <div onClick={() => movePage(1)} className="text-custom2 relative bottom-0  hover:font-bold">&#10230;</div>
                    </div>
                    <div className=""></div>

                </div> */}

            </div>
            <div className="w-screen hidden h-20 lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                <div className="hidden lg:flex items-center text-2xl ml-auto mr-14 cursor-pointer ">
                    <div onClick={() => movePage(-1)} className="text-custom2 relative bottom-0 hover:text-amber-400  hover:font-bold">&#10229;</div>
                    <div onClick={() => movePage(1)} className="text-custom2 relative bottom-0 hover:text-amber-400 hover:font-bold">&#10230;</div>
                </div>

            </div>


            <TeamDialog show={open && (globalSw < 1024)} teamI={teamI} onClose={handleClose} />

            <TeamScrollOver show={open} teamI={teamI} theme={theme} onClose={handleClose} />
        </div>
    )
}