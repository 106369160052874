import { clients, getBg, urlBase, getBgAttachment, getBgSize } from "./constants";
import { NavBarLeft } from "./navbarleft";
export const Clients = ({ theme, showSubtext, globalSw, headerLinkClick }) => {
    return (
        <div
            id="page6"
            style={{ backgroundImage: getBg(5, theme, globalSw), backgroundRepeat: 'no-repeat', backgroundSize: getBgSize(5, theme, globalSw), backgroundAttachment: getBgAttachment(5, theme, globalSw) }}
            className=" flex-col z-30 top-0  left-0 flex lg:h-screen w-screen  py-20 lg:py-0"
        >
            <div className="flex flex-col max-w-screen-2xl px-8 mt-8 lg:ml-20 h-full">
                <div className="h-full flex flex-col  lg:grid grid-cols-3">
                    <div className=" col-span-1 flex">
                        <div className="flex flex-col center-text-6xl m-auto">
                            <div className="text-3xl lg:text-5xl xl:text-6xl mb-4">
                                Our Clients<br></br>Trust Us
                            </div>
                            {showSubtext ?
                                <div className="text-custom1 ml-1 lg:font-18 mb-8 slidein lg:mb-0">
                                    We carefully curate the clients we take on.
                                </div> : <div className="text-custom1 opacity-0 lg:font-18 mb-8  lg:mb-0">
                                    We carefully curate the clients we take on.
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-span-2 flex">
                        <div className={"grid grid-cols-2 lg:grid-cols-3 gap-x-12 clientoffset gap-y-6 lg:gap-y-14  lg:relative grid-flow-row m-auto " + ((theme == 'cherry' && globalSw > 1024) ? 'mr-custom-2' : '')}>
                            {clients.map((x, i) => (

                                <a href={x.link} className="flex" key={i} >
                                    <img src={x.img} className={"m-auto grayscale scale-75 " + (theme == 'dark' ? " mix-blend-lighten invert" : "")} alt={x.name} />
                                </a>
                            ))}
                            <div></div>
                            {/* <a href={clients[clients.length - 1].link} className="flex"  >
                                <img src={`${urlBase}${clients[clients.length - 1].img}`} className={"m-auto  scale-75 grayscale " + (theme == 'dark' ? " mix-blend-lighten invert" : "")} alt={clients[clients.length - 1].name} />
                            </a> */}
                        </div>
                    </div>
                </div>


            </div>
            {/* <div className="w-full lg:relative lg:bottom-12 lg:right-12 lg:h-20 right-40 mr-14 flex">
                <div className=" hidden lg:flex items-center text-2xl ml-auto cursor-pointer hover:text-amber-400">
                    <div onClick={() => headerLinkClick(3)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="font-18 hover:text-amber-400">Clients{" "}</div>
                    <div onClick={() => headerLinkClick(5)} className="text-custom2 relative bottom-0  hover:font-bold">&#10230;</div>
                </div>
            </div> */}

            {/* <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                <div className=""></div>
                <div className=" hidden lg:flex items-center text-2xl  mx-auto cursor-pointer hover:text-amber-400">
                    <div onClick={() => headerLinkClick(4)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="font-18 hover:text-amber-400">Clients{" "}</div>
                    <div onClick={() => headerLinkClick(6)} className="text-custom2 relative bottom-0  hover:font-bold">&#10230;</div>
                </div>
                <div className=""></div>

            </div> */}

            <div className="w-screen hidden h-20 lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer ">
                    <div onClick={() => headerLinkClick(4)} className="text-custom2 relative bottom-0  hover:text-amber-400 hover:font-bold">&#10229;</div>
                    <div onClick={() => headerLinkClick(6)} className="text-custom2 relative bottom-0 hover:text-amber-400 hover:font-bold">&#10230;</div>
                </div>

            </div>
        </div>
    )
}